import React, {Suspense} from 'react';

import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./App.css";

import LoadingSpinner from './Components/UI/LoadingSpinner';
import Header from './pages/Header';
import ContactForm from "./Components/Contact Me/ContactForm";
import GreetingPage from "./pages/GreetingPage";

const Home = React.lazy(() => import("./pages/Home"));
const Cart = React.lazy(() => import("./Components/Cart/Cart"));
const ProductsList = React.lazy(() => import("./Components/Products/ProductsList"));
const Works = React.lazy(() => import('./pages/Works'));

function App() {
  return (
    <div>
      <Header />
      <main className="main">
      <Suspense fallback={<LoadingSpinner/>}>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="cart/*" element={<Cart />} />
          </Route>

          <Route path="/greeting-email" element={<GreetingPage/>} ></Route>

          <Route path="/mail-me" element={<ContactForm/>}></Route>

          <Route path="/products" element={<ProductsList />}>
            <Route path="cart/*" element={<Cart />} />
          </Route>

          <Route path="/works" element={<Works/>}>
            <Route path="cart/*" element={<Cart />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
        </Suspense>
      </main>
    </div>
  );
}

export default App;
